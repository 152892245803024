<template>
	<div class="app-container" :style="{direction: yuyan.now=='hanyu'?	'ltr':'rtl'}">

		<el-row class="susu-margin" :gutter="24">

			<el-col :span="24" style="margin-bottom: 20px">

				<el-alert :title="yuyan.preview_exam_title" type="error" style="margin-bottom: 10px" />

				<el-card class="pre-exam">

					<div><strong>{{yuyan.preview_exam_name}}：</strong>{{ detailData.title }}</div>
					<div><strong>{{yuyan.preview_exam_time}}：</strong>{{ detailData.totalTime }}{{yuyan.fenzhong}}</div>
					<div><strong>{{yuyan.preview_exam_score}}：</strong>{{ detailData.totalScore }}{{yuyan.fen}}</div>
					<div><strong>{{yuyan.preview_exam_pass}}：</strong>{{ detailData.qualifyScore }}{{yuyan.fen}}</div>
					<div><strong>{{yuyan.preview_exam_script}}：</strong>{{ detailData.content }}</div>
					<div><strong>{{yuyan.preview_exam_type}}：</strong> {{ detailData.openType }}</div>

				</el-card>

			</el-col>

			<el-col class="susu-juzhong" :span="24">

				<el-button type="primary" icon="el-icon-caret-right" @click="handleCreate">
					{{yuyan.exam_start}}
				</el-button>

				<el-button @click="handleBack">
					{{yuyan.fanhui}}
				</el-button>

			</el-col>

		</el-row>
	</div>
</template>

<script>
	// import { Loading } from 'element-ui'
	// import { fetchDetail } from '@/api/preview'
	import {
		createPaper
	} from '@/api/exam'
	import {
		fetchDetail
	} from '@/api/exam'
	export default {
		name: 'PreExam',
		data() {
			return {
				yuyan: {},
				detailData: {},
				postForm: {
					examId: '',
					password: ''
				},
				rules: {
					password: [{
						required: true,
						message: '考试密码不能为空！'
					}]
				}
			}
		},

		created() {
			this.postForm.examId = this.$route.query.examId
			this.fetchDatas()
			this.yuyan = this.$language()
		},

		watch: {
			'$store.state.language_num'(to, from) {
				this.yuyan = this.$language()
			}
		},

		methods: {

			fetchDatas() {
				fetchDetail(this.postForm.examId).then(response => {
					this.detailData = response
				})
			},

			handleCreate() {
				const that = this
				createPaper(this.postForm).then(response => {
					this.$message({
						message: that.yuyan.preview_exam_start,
						type: 'success'
					})
					setTimeout(function() {
						// loading.close()
						that.dialogVisible = false
						that.$router.push({
							path: '/start',
							query: {
								id: response.id
							}
						})
					}, 1000)
				}).catch(() => {
					// loading.close()
				})




				// 打开
				// const loading = Loading.service({
				//   text: '正在努力创建试卷...',
				//   background: 'rgba(0, 0, 0, 0.7)'
				// })

				//   createPaper(this.postForm).then(response => {
				//     console.log(response)

				//     // if (response.code === 0) {
				// if (1) {
				//       this.$message({
				//         message: '试卷创建成功，即将进入考试！',
				//         type: 'success'
				//       })

				//       setTimeout(function() {
				//         // loading.close()
				//         that.dialogVisible = false
				// 		that.$router.replace('/start')
				//       }, 1000)
				//     }
				//   }).catch(() => {
				//     // loading.close()
				//   })
			},

			handleBack() {
				this.$router.push('/exam')
			}

		}
	}
</script>

<style scoped>
	.app-container {
		background-color: #d8dde7;
		height: 100%;
		width: 100%;
		overflow: hidden;
	}

	.pre-exam div {

		line-height: 42px;
		color: #555555;
	}

	.susu-juzhong {
		margin-top: 20px;
		display: flex;
		justify-content: center;
	}

	.susu-margin {
		padding: 90px;
	}
</style>
